import React from 'react'
import Layout from '../../components/layout'
import Header from '../../components/Header'
import { Helmet } from 'react-helmet'
import Sidebar from '../../components/Sidebar/Sidebar'

const PuroliteLt = () => (
    <Layout>
      <Helmet>
          <title>Purolite Joninių Mainų Dervos</title>
      </Helmet>
      <Header         
        styleName='header'
        title='Purolite joninių mainų dervos'/>
      <div className="columns">
        <div className="column content">
          <div className="content-text">UAB "Inžinerinė ekologija" yra oficialus kompanijos Purolite atstovas Baltijos šalyse. Mes siūlome platų joninių mainų dervų asortimentą (katijoninės, anijoninės ir mišrios dervos, sorbentai), naudojamų geriamojo bei  technologinio vandens valymui ir paruošimui,  specifiniams technologiniams procesams pramonėje.</div>
          <table className="table content-table-purolite">
            <thead>
            <th>Produktas</th>
            <th>Paskirtis</th>  
            </thead>
            <tbody>
            <tr>
              <td>Purolite C100E</td>
              <td> Geriamojo vandens minkštinimui, katijonitas (Na+ forma), maistinė klasė</td>
            </tr>
            <tr>
              <td>Purolite C100H</td>
              <td>Stipriai rūgštinis katijonitas skirtas demineralizacijai, SAC (H+ forma)</td>
            </tr>
            <tr>
              <td>Purolite C104Plus</td>
              <td> Silpnai rūgštinis katijonitas skirtas minkštinimui, dekarbonizacijai (šarmingumui šalinti) ir demineralizacijai, WAC (H+ forma)</td>
            </tr>
            <tr>
              <td>Puropack PPC100</td>
              <td> Puropack katijonitas – tai specialaus dalelių dydžio (650 ± 50 µm) medžiaga, skirta vandens minkštinimui arba demineralizacijai. Derva pasižymi dideliu darbiniu imlumu, mechaniniu atsparumu, efektyviu darbu esant dideliam srauto greičiui bei atbulinei regeneracijai (Na+ arba H+ forma)</td>
            </tr>
            <tr>
              <td>Purolite A520E</td>
              <td> Anijonitas skirtas nitratų šalinimui iš geriamojo vandens, (Cl- forma), maistinė klasė</td>
            </tr>
            <tr>
              <td>Purolite A502PS</td>
              <td>Makroporinis anijonitas-sorbentas, skirtas šalinti organinėms medžiagoms, spalvai ir kvapui, (Cl- forma), maistinė klasė</td>
            </tr>
            <tr>
              <td>Purolite A400 OH</td>
              <td> Stipriai šarminis anijonitas skirtas demineralizacijai, SBA (OH- forma, gelinis)</td>
            </tr>
            <tr>
              <td>Purolite A500 OH</td>
              <td> Stipriai šarminis anijonitas skirtas demineralizacijai, SBA (OH- forma, makroporinis), pasižymi dideliu mechaniniu ir osmotiniu stabilumu, bei atsparumu organinei taršai</td>
            </tr>
            <tr>
              <td>Purolite A400 MBOH IND (blue)</td>
              <td> Stipriai šarminis anijonitas su indikatoriumi, skirtas rūgštinių (HCl) garų adsorbcijai (mėlynas, išsieikvojus praranda spalvą)</td>
            </tr>
            <tr>
              <td>Purolite MB400</td>
              <td> Mišri derva, skirta nudruskinimui iki 0,1 µS/cm, mišinys paruoštas naudojimui ir sudarytas iš katijonito 40% ir 60% anijonito</td>
            </tr>
            <tr>
              <td>Purolite MB46LT</td>
              <td> Mišri derva skirta nudruskinimui ir EDM procesams, mišinys paruoštas naudojimui ir sudarytas iš katijonito 50% ir 50% anijonito</td>
            </tr>
            <tr>
              <td>Purolite S108</td>
              <td>Derva selektyviam boro šalinimui iš geriamojo, pramoninio/technologinio ir nuotekų vandens, maistinė klasė</td>
            </tr>
            </tbody>
          </table>
          <br/>
          <div className="content-text"> Klientams teikiame technines konsultacijas. Dėl Purolite produktų tiekimo kreipkites adresu info@in-eco.biz.</div>
        </div>
        <Sidebar
        analysis='Anketos'
        boiler='Boileriams'
        cooling='Aušinimui'
        ro='Osmosui'
        broshure='IN-ECO brošiura'
        certificates='Sertifikatai'
        />
      </div>
    </Layout>

)

export default PuroliteLt